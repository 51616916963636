@use '../../../styles/colors';

.title-button-row {
  display: flex;
  justify-content: space-between;
  margin-bottom: 1rem;
}

.description {
  height: 100%;
  border: none;
}

.details-box {
  max-width: 500px;
}

.row-width {
  width: 18px;
}
