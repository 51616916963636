@use "./colors";
@use "./typography";

html,
body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
    Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
  background-color: #fcfcfc;
}

a {
  color: #4abaeb;
}

* {
  box-sizing: border-box;
}

.content {
  padding: 2rem;
}

.react-flow__handle {
  visibility: hidden !important;
  width: 20px;
}

.react-flow__node {
  cursor: default;
}
