// Primary brand colors
$summer-sky: #4abaeb;
$endeavor: #225a8a;
$havelock-blue: #5080c1;
$bright-gray: #595a5c;

// Secondary brand colors
$waikawa-gray: #5f7a90;
$bahama-blue: #204285;
$jordy-blue: #74a6d9;
$medium-aquamarine: #5dc4b6;
$downy: #63c8d2;

// Web-only colors
$neon-carrot: #ffa729;
$picton-blue: #309fcf;
$revolver: #2f2f31;
$silver: #cbcbcb;
$st-tropaz: #215989;
$royal-blue: #3879df;
$active-blue: rgb(0, 150, 217);
// grayscale
$true-black: #000000;
$black: #151515;
$darkest-gray: #222222;
$dark-gray: #999999;
$medium-gray: #d9d9d9;
$gray: #e5e5e5;
$light-gray: #f8f8f8;
$white: #ffffff;


$mui-transparent-gray: #a8a8ae;
$background-grey: rgb(239, 239, 239);
$transparent: rgba(0, 0, 0, 0);
