@use '../../styles/colors';

.box {
  height: 510px;
  overflow-y: auto;
  margin-top: 20px;
  padding: 5px;

  border: 1px solid colors.$gray;
  border-radius: 4px;
}

.close {
  float: right;
}

.title {
  border-bottom: 1px solid colors.$gray;
}

.content {
  padding-top: 30px !important;
}

.actions {
  border-top: 1px solid colors.$gray;
}
