/* --------------------------------- Layout --------------------------------- */

.hidden {
    display: none;
}

.float-right {
  float: right;
}

.flex {
  display: flex;
}

.flex {
  display: flex;
}

.flex-wrap {
  flex-wrap: wrap;
}

.flex-row {
  display: flex;
  flex-direction: row;
}

.align-center {
  align-items: center;
}

/* ------------------------------ Width/Padding ----------------------------- */
.form-width {
  width: 400px;
}

$spacer: 1rem;
$spacers: (
  5: (
    $spacer * 2,
  ),
  4: (
    $spacer * 1.5,
  ),
  3: $spacer,
  2: (
    $spacer * 0.5,
  ),
  1: (
    $spacer * 0.25,
  ),
  0: 0,
);

/* stylelint-disable declaration-no-important */

@each $prop, $abbrev in (margin: m, padding: p) {
  @each $size, $length in $spacers {
    .#{$abbrev}-#{$size} {
      #{$prop}: $length !important;
    }

    .#{$abbrev}t-#{$size},
    .#{$abbrev}y-#{$size} {
      #{$prop}-top: $length !important;
    }

    .#{$abbrev}r-#{$size},
    .#{$abbrev}x-#{$size} {
      #{$prop}-right: $length !important;
    }

    .#{$abbrev}b-#{$size},
    .#{$abbrev}y-#{$size} {
      #{$prop}-bottom: $length !important;
    }

    .#{$abbrev}l-#{$size},
    .#{$abbrev}x-#{$size} {
      #{$prop}-left: $length !important;
    }
  }
}
