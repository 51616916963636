@use '../../styles/colors';

.container {
  min-width: 800px;
  max-width: 1600px;
  padding: 0;
  margin: auto;
  margin-bottom: 2rem;
  border: 1px solid #d9d9d9;
  z-index: 1;
  position: relative;
}
